import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Imprint = () => (
  <Layout>
    <SEO title="Imprint" />
    <div className="container static-page-container pd-top-static-pg pd-bottom-static-pg">
      <div className="row">
        <div className="col-md-12">        
          <h1><b>Imprint / Impressum</b></h1>
          <br/>
          <p>Angaben gemäß § 5 TMG, § 55 RStV</p>

          <p>Verantwortlich für den Inhalt dieser Website:</p>

          <p>framehub UG (haftungsbeschränkt)</p>

          <p>Geschäftsführer: Felix Buschkotte</p>

          <p>Obernstraße 50</p>

          <p>33602 Bielefeld</p>

          <p>Deutschland</p>

          <a href="mailto:felix@framehub.de">felix@framehub.de</a>

          <p>Inhaltlich verantwortlicher: Felix Buschkotte</p>

          <a href="tel:+49 176 232 653 94">+49 176 232 653 94</a>

          <p>Registergericht Bielefeld, HRB:</p>

          <p>42527</p>

          <p>Ust-ID:</p>

          <p>DE312717238</p>

          <p>Haftungshinweis</p>

          <p>Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Gewähr für Vollständigkeit, Aktualität und Richtigkeit der Inhalte dieser Website. Gleiches gilt insbesondere für die Inhalte externer Links. Für die Inhalte der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.</p>

          <p>Die Haftung für Schäden, die sich aus der Verwendung dieser Website und der in ihr enthaltenen Inhalte / Informationen ergeben, wird ausdrücklich ausgeschlossen. Hiervon ausgenommen ist die Haftung für Vorsatz und grobe Fahrlässigkeit.</p>

          <br />
          <Link to="/" className="back-to-home-btn1">Back to Home</Link>

        </div>
      </div>
    </div>
  </Layout>
)

export default Imprint
